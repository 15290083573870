import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { AppComponent } from './app.component';
import { CoreModule } from './core';
import { AuthenticationGuard } from './modules/authentication';
import { SeoModule } from './modules/seo';
import { NetworkModule } from './modules/network';
import { environment } from '../environments/environment';
import { UserModule } from './modules/user';
import { DocumentServiceModule } from './modules/document';
import { provideRoutes, RouterModule } from '@angular/router';
import { WorkspaceModule } from './modules/workspace';
import { PppModule } from './modules/ppp';
import { AuthenticationModule } from './modules/authentication/authentication.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'sapp' }),
    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFireAnalyticsModule,
    // AngularFireAuthModule,
    RouterModule.forRoot([], { initialNavigation: 'enabled' }),
    BrowserAnimationsModule,
    // CoreModule,
    // HttpClientModule,
    // NetworkModule,
    // SeoModule,
    // DocumentServiceModule,
    // AuthenticationModule.forRoot(),
    // WorkspaceModule.forRoot([AuthenticationGuard]),
    // PppModule.forRoot(),
    // UserModule.forRoot(),
  ],
  providers: [
    provideRoutes([
      {
        path: '',
        loadChildren: () => import('./modules/landing/landing-routed.module').then(x => x.LandingRoutedModule),
      },
    ]),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
