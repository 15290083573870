// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from './model';

export const environment: Environment = {
  production: false,
  useWorkspace: true,
  apiUri: 'http://localhost:4000/api',
  firebase: {
    apiKey: 'AIzaSyDkVSE_eTlwOLpdycKG_oVvJXC-HmRFQAQ',
    authDomain: 'lendingqube-ppp.firebaseapp.com',
    databaseURL: 'https://lendingqube-ppp.firebaseio.com',
    projectId: 'lendingqube-ppp',
    storageBucket: 'lendingqube-ppp.appspot.com',
    messagingSenderId: '789189262389',
    appId: '1:789189262389:web:b2c89dba12524bba0b057c',
    measurementId: 'G-VSHBPQNKGW'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
